import {FaAngleLeft, FaAngleRight} from "react-icons/fa6";
import Carousel from "react-spring-3d-carousel";
import React, {useContext, useState} from "react";
import {mainSlides} from "../../app.data";
import {AppContext} from "../../App.context";

export default function MainCarouselComponent() {
    const {showFullScreenImages} = useContext(AppContext);
    const [slide, setSlide] = useState(0);
    const [xDown, setDownX] = useState(0 as number | null);
    const [yDown, setDownY] = useState(0 as number | null);

    const handleTouchBreak = (evt: any) => {
        setDownX(null);
        setDownY(null);
    }

    const handleTouchStart = (evt: any) => {
        const firstTouch = getTouches(evt)[0];
        setDownX(firstTouch.clientX);
        setDownY(firstTouch.clientY);
    };

    const handleTouchMove = (evt:any) => {
        if (xDown && yDown) {
            let xUp = getTouches(evt)[0].clientX;
            let yUp = getTouches(evt)[0].clientY;
            let xDiff = xDown - xUp;
            let yDiff = yDown - yUp;
            if (Math.abs(xDiff) > Math.abs(yDiff)) {
                if (xDiff > 0) {
                    /* left swipe */
                    setDownX(null);
                    setDownY(null);
                    setSlide(prev => prev + 1);
                } else {
                    /* right swipe */
                    setDownX(null);
                    setDownY(null);
                    setSlide(prev => prev - 1);
                }
            }
        }
    };

    return (
        <>
            <div style={{margin: "0 auto"}}
                 className={'relative select-none w-full h-56 sm:w-2/3 sm:h-72 lg:h-[500px]'}
                 onMouseDown={handleTouchStart}
                 onMouseUp={handleTouchBreak}
                 onMouseMove={handleTouchMove}
                 onTouchStart={handleTouchStart}
                 onTouchMove={handleTouchMove}
                 onClick={ _ => showFullScreenImages(mainSlides, slide)}
            >

                <div
                    className={'hidden md:flex flex-col absolute w-full items-center h-full justify-center z-10'}>
                    <div
                        className={'flex flex-row w-11/12 sm:w-10/12 md:w-5/6 lg:w-11/12 xl:w-4/5 justify-between'}>
                        <div
                            className={'cursor-pointer select-none bg-light-main/80 dark:bg-dark-bg/80 h-8 w-8 md:h-16 md:w-16 flex flex-col justify-center items-center rounded-md hover:bg-light-main dark:hover:bg-dark-bg'}
                            onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                setSlide(prev => prev - 1)
                            }}>
                            <FaAngleLeft className={'text-white h-5 w-5 md:h-10 md:w-10'}/>
                        </div>

                        <div
                            className={'cursor-pointer select-none bg-light-main/80 dark:bg-dark-bg/80 h-8 w-8 md:h-16 md:w-16 flex flex-col justify-center items-center rounded-md hover:bg-light-main dark:hover:bg-dark-bg'}
                            onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                setSlide(prev => prev + 1)
                            }}>
                            <FaAngleRight className={'text-white h-5 w-5 md:h-10 md:w-10'}/>
                        </div>

                    </div>
                </div>


                <Carousel
                    slides={mainSlides.map( (src, index) => {

                        return {
                            key: index + 1,
                            content: <img className={'max-w-fit lg:max-w-max cursor-grabbing'}
                                          src={src}
                                          alt="..."/>
                        }
                    })}
                    goToSlide={slide}
                    offsetRadius={1}
                    showNavigation={false}
                    animationConfig={{tension: 80, friction: 14}}
                />
            </div>
        </>
    );
}

export const getTouches = (evt: React.TouchEvent | React.MouseEvent | any) => {
    console.log(evt);

    if (evt.touches) return evt.touches;
    if (evt.originalEvent && evt.originalEvent.touches) return evt.originalEvent.touches;


    return [{clientX: evt.clientX, clientY: evt.clientY}];
};