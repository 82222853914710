import {Button, Card} from "flowbite-react";
import React, {useContext, useRef, useState} from "react";
import {AppRoom} from "../../app.data";
import {AppContext} from "../../App.context";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";

interface Props {
    room: AppRoom;
}

export default function RoomCardComponent({room}: Props) {
    const {t} = useTranslation();
    const {language, filterByCategory} = useContext(AppContext);
    const navigate = useNavigate();
    const [hover, isHover] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [showIcons, setShowIcons] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    const state = useRef<'init' | 'hovering' | 'showed' | 'hiding'>('init');

    const _showButtons = () => {
        if (state.current !== 'hiding') {
            setShowButtons(true);
            state.current = "showed";
        }
    }

    const _showIcons = () => {
        if (state.current !== 'hiding') {
            setShowIcons(true);
            setTimeout( () => _showButtons(), 200);
        }
    }

    const _showInfo = () => {
        if (state.current !== 'hiding') {
            setShowInfo(true);
            setTimeout( () => _showIcons(), 200);
        }
    };

    const show = () => {
        if (state.current === 'init') {
            state.current = "hovering";

            isHover(true);
            setTimeout( () => _showInfo(), 200);
        }
    };

    const hide = () => {
        if (state.current === 'showed' || state.current === 'hovering') {
            state.current = "hiding";

            setShowInfo(false);
            setShowIcons(false);
            setShowButtons(false);
            setTimeout( () => hidding(), 300);
        }
    }

    const hidding = () => {
        isHover(false);
        state.current = "init";
    }

    const toggle = (e: React.TouchEvent | any) => {
        switch (e.type) {
            case 'click':
                if (state.current !== 'showed') {
                    show();
                } else {
                    hide();
                }
                break;
            case 'mouseenter':
                show();
                break;
            case 'mouseleave':
                hide();
                break;
        }
    }

    const [isImgLoaded, setImgLoaded] = useState(false);

    const bookRoom = (e: React.MouseEvent | any, id?: string): void => {
        e.preventDefault();
        e.stopPropagation();

        if (id) {
            filterByCategory(id);
            navigate('/book', {unstable_viewTransition: true})
        }
    }

    return (
        <Card
            // className={`${isImgLoaded ? `` : 'animate-pulse bg-gray-400'} flex w-[350px] h-[500px] md:w-[500px] md:h-[750px] bg-cover cursor-pointer m-1 transition-all duration-500`}
            className={`${isImgLoaded ? `` : 'animate-pulse bg-gray-400'} flex w-[350px] h-[350px] sm:w-[500px] sm:h-[500px] md:w-[700px] md:h-[700px] bg-cover cursor-pointer m-1 transition-all duration-500`}
            style={{backgroundImage: `URL('${isImgLoaded ? room.image : ''}')`}}
            onMouseEnter={toggle}
            onMouseLeave={toggle}
            onClick={toggle}

        >

            <img
                width={0}
                height={0}
                src={room.image}
                alt={'card'}
                onLoad={_ => setImgLoaded(true)}
            />

            <div className={`flex flex-col justify-end basis-full`}>

                <div
                    className={`${hover ? 'basis-full bg-light-main/90 dark:bg-dark-bg/90' : 'basis-[60px] sm:basis-[70px] md:basis-[95px] bg-light-main/60 dark:bg-dark-bg/60'} relative flex flex-col sm:justify-end transition-all duration-500 text-light-text-navbar`}>
                    <div
                        className={`${hover ? 'mt-1 sm:mt-0 sm:top-[15px]' : 'mt-3 sm:mt-0 top-auto'} absolute w-full flex flex-row justify-between md:mb-3`}>
                        <p className={'flex basis-2/3 m-1 mx-5 sm:m-5 content-center text-sm sm:text-lg md:text-2xl self-center '}>
                            {room.title[language]}
                        </p>

                        <div
                            className={'flex flex-shrink-0 flex-col justify-center mr-4'}>
                            <div
                                className='block h-fit text-sm sm:text-lg md:text-2xl px-4 py-2 rounded-full bg-light-main dark:bg-dark-bg'>
                                {room.price} {t('currency')}
                            </div>

                        </div>
                    </div>

                    <div
                        className={`${hover ? 'opacity-100 flex' : 'opacity-0 hidden'} absolute top-7 sm:top-20 p-4 flex flex-col basis-full h-[290px] sm:h-[450px] md:h-[700px] transition-all duration-500 `}>

                        <p className={`${showInfo ? 'opacity-100' : 'opacity-0'} pb-1 sm:pb-4 transition-all duration-500 text-xs md:text-sm`}>
                            {room.description[language]}
                        </p>

                        <div
                            className={`${showIcons ? 'opacity-100' : 'opacity-0'} flex flex-col flex-wrap basis-full max-h-[180px] md:max-h-[400px] max-w-[480px] overflow-x-hidden transition-all duration-500 `}>

                            <div className={'flex flex-row w-1/2'}>
                                <div className={'self-center w-[32px]'}>
                                    <img src='/icons/light/People.png' alt={'icon'} width={32}/>
                                </div>

                                <p className={'py-1 px-2 text-xs sm:text-sm'}>{room.capacity[language]}</p>
                            </div>

                            {room.facilities.map((icon, index) => (
                                <div className={'flex flex-row w-1/2'} key={index}>
                                    <div className={'self-center w-[32px]'}>
                                        <img src={icon.icon} alt={'icon'} width={32}/>
                                    </div>

                                    <p className={'py-1 px-2 text-xs sm:text-sm'}>{icon.title[language]}</p>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div
                        className={`${showButtons ? 'opacity-100 flex' : 'opacity-0 hidden'} transition-all duration-300 flex-col-reverse basis-full sm:basis-1/2 gap-2`}>
                        <div className={'flex flex-row gap-2 pb-2 px-2'}>
                            <Link to={`/rooms/${room.internalId}`} className={'w-1/2'} unstable_viewTransition={true}>
                                <Button size={'sm'} color={'default'} className={'w-full h-full !text-xs sm:!text-sm'}>
                                    {t('button.more_details')}
                                </Button>
                            </Link>
                            <Button size={'xs'} color={'default'} className={'w-1/2 px-2 py-1 sm:px-6 sm:py-3 !text-xs sm:!text-sm'}
                                     onClick={e => bookRoom(e, room.externalId)}>
                                {t('button.book')}
                            </Button>

                        </div>
                    </div>
                </div>

            </div>
        </Card>
    );
}