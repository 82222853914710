export interface AppRoomFacility {
    icon: string;
    title: { en: string, uk: string, ru: string};
}

export interface AppRoom {
    internalId: string;
    externalId: string;
    price: number;
    curPrice?: number;
    availability: number;
    image: string;
    title: { en: string, uk: string, ru: string};
    capacity: { en: string, uk: string, ru: string};
    description: { en: string, uk: string, ru: string};
    facilities: AppRoomFacility[];
    gallery: string[];
}

export const mainSlides: string[] = [
    "/imgs/slider/1.jpg",
    "/imgs/slider/2.jpg",
    "/imgs/slider/3.jpg",
    "/imgs/slider/4.jpg",
    "/imgs/slider/5.jpg",
    "/imgs/slider/6.jpg",
    "/imgs/slider/7.jpg",
    "/imgs/slider/8.jpg",
    "/imgs/slider/9.jpg",
    "/imgs/slider/10.jpg",
    "/imgs/slider/11.jpg",
];

export const appData: AppRoom[] = [
    {
        internalId: 'studio-2-person',
        externalId: '13818',
        price: 1080,
        availability: 1,
        image: "/imgs/gallery/studio-2/1.jpg",
        title: {
            en: 'Studio Suite double',
            uk: 'Студіо 2х місний',
            ru: 'Студио 2х местный'
        },
        description: {
            en: "The suite includes a living room, a well-equipped kitchen (a fridge, oven, kettle), dining zone and a bathroom with a shower. Air conditioning. High-speed Internet. Free parking.",
            uk: 'Апартаменти складаються з вітальні, добре обладнаної кухні з обідньою зоною та власної ванної кімнати з душем. Кондиціонер. Швидкісний інтернет. Надається парковка.',
            ru: 'Апартаменты состоят из гостиной, хорошо оборудованной кухни с обеденной зоной и собственной ванной комнаты с душем. Кондиционер. Скоростной интернет. Предоставляется парковка.'
        },
        capacity: {
            en: '2 placements',
            uk: '2 місця',
            ru: '2 места'
        },
        facilities: [
            { icon: '/icons/light/Dinner.png', title: { en: 'Dinner zone', uk: 'Обідня зона', ru: 'Обеденная зона' }},
            { icon: '/icons/light/Kitchens.png', title: { en: 'Own kitchen', uk: 'Власна кухня', ru: 'Собственная кухня' }},
            { icon: '/icons/light/Toilets.png', title: { en: 'Bathroom with all amenities', uk: 'Ванна з усіма зручностями', ru: 'Ванная со всеми удобствами' }},
            { icon: '/icons/light/Condicioner.png', title: { en: 'Air conditioner', uk: 'Кондиціонер', ru: 'Кондиционер' }},
            { icon: '/icons/light/TV.png', title: { en: 'TV', uk: 'ТБ', ru: 'Телевизор' }},
            { icon: '/icons/light/Dryer.png', title: { en: 'Hairdryer', uk: 'Фен', ru: 'Фен' }},
            { icon: '/icons/light/Parking.png', title: { en: 'Parking', uk: 'Парковка', ru: 'Парковка' }},
        ],
        gallery: [
            "/imgs/gallery/studio-2/1.jpg",
            "/imgs/gallery/studio-2/2.jpg",
            "/imgs/gallery/studio-2/3.jpg",
            "/imgs/gallery/studio-2/4.jpg",
            "/imgs/gallery/studio-2/5.jpg",
        ]
    },
    {
        internalId: 'suite',
        externalId: '13815',
        price: 1350,
        availability: 1,
        image: '/imgs/rooms/separate-bedroom.png',
        title: {
            en: 'One Bedroom Suite',
            uk: 'Апартаменти зі спальнею',
            ru: 'Апартаменты со спальней'
        },
        description: {
            en: "The suite includes a living room, a well-equipped kitchen (a fridge, oven, kettle), dining zone, a bedroom with a big bed and a bathroom with a shower. Air conditioning. High-speed Internet. Free parking.",
            uk: 'Апартаменти складаються з вітальні, добре обладнаної кухні з обідньою зоною, окремої спальні з великим ліжком та власною ванною кімнатою з душем. Кондиціонер. Швидкісний інтернет. Надається парковка.',
            ru: 'Апартаменты состоят из гостиной, хорошо оборудованной кухни с обеденной зоной, отдельной спальни с большой кроватью и собственной ванной комнаты с душем. Кондиционер. Скоростной интернет. Предоставляется парковка.'
        },
        capacity: {
            en: '4 placements',
            uk: '4 місця',
            ru: '4 места'
        },

        facilities: [
            { icon: '/icons/light/Dinner.png', title: { en: 'Dinner zone', uk: 'Обідня зона', ru: 'Обеденная зона' }},
            { icon: '/icons/light/Kitchens.png', title: { en: 'Own kitchen', uk: 'Власна кухня', ru: 'Собственная кухня' }},
            { icon: '/icons/light/Rooms.png', title: { en: 'Bedroom', uk: 'Спальня', ru: 'Спальня' }},
            { icon: '/icons/light/Toilets.png', title: { en: 'Bathroom with all amenities', uk: 'Ванна з усіма зручностями', ru: 'Ванная со всеми удобствами' }},
            { icon: '/icons/light/Condicioner.png', title: { en: 'Air conditioner', uk: 'Кондиціонер', ru: 'Кондиционер' }},
            { icon: '/icons/light/TV.png', title: { en: 'TV', uk: 'ТБ', ru: 'Телевизор' }},
            { icon: '/icons/light/Dryer.png', title: { en: 'Hairdryer', uk: 'Фен', ru: 'Фен' }},
            { icon: '/icons/light/Parking.png', title: { en: 'Parking', uk: 'Парковка', ru: 'Парковка' }},
        ],
        gallery: [
            "/imgs/gallery/suite/2.jpg",
            "/imgs/gallery/suite/1.jpg",
            "/imgs/gallery/suite/3.jpg",
            "/imgs/gallery/suite/4.jpg",
            "/imgs/gallery/suite/5.jpg",
            "/imgs/gallery/suite/6.jpg",
            "/imgs/gallery/suite/7.jpg",
        ]
    },
    {
        internalId: 'lux',
        externalId: '13816',
        price: 1580,
        availability: 1,
        image: '/imgs/rooms/lux.png',
        title: {
            en: 'Duplex Suite',
            uk: '2х рівневий люкс',
            ru: '2х уровневый люкс'
        },
        description: {
            en: "The suite includes a living room, a well-equipped kitchen (a fridge, oven, kettle), dining zone, a second-floor bedroom with a big bed and a bathroom with a shower. Air conditioning. High-speed Internet. Free parking.",
            uk: 'Апартаменти складаються з вітальні, добре обладнаної кухні з обідньою зоною, окремої спальні з великим ліжком на другому рівні та власної ванної кімнати з душем. Кондиціонер. Швидкісний інтернет. Надається парковка.',
            ru: 'Апартаменты состоят из гостиной, хорошо оборудованной кухни с обеденной зоной, отдельной спальни с большой кроватью расположенной на втором уровне и собственной ванной комнаты с душем. Кондиционер. Скоростной интернет. Предоставляется парковка.'
        },
        capacity: {
            en: '4 placements',
            uk: '4 місця',
            ru: '4 места'
        },
        facilities: [
            { icon: '/icons/light/Dinner.png', title: { en: 'Dinner zone', uk: 'Обідня зона', ru: 'Обеденная зона' }},
            { icon: '/icons/light/Kitchens.png', title: { en: 'Own kitchen', uk: 'Власна кухня', ru: 'Собственная кухня' }},
            { icon: '/icons/light/Rooms.png', title: { en: 'Bedroom', uk: 'Спальня', ru: 'Спальня' }},
            { icon: '/icons/light/Toilets.png', title: { en: 'Bathroom with all amenities', uk: 'Ванна з усіма зручностями', ru: 'Ванная со всеми удобствами' }},
            { icon: '/icons/light/Condicioner.png', title: { en: 'Air conditioner', uk: 'Кондиціонер', ru: 'Кондиционер' }},
            { icon: '/icons/light/TV.png', title: { en: 'TV', uk: 'ТБ', ru: 'Телевизор' }},
            { icon: '/icons/light/Dryer.png', title: { en: 'Hairdryer', uk: 'Фен', ru: 'Фен' }},
            { icon: '/icons/light/Parking.png', title: { en: 'Parking', uk: 'Парковка', ru: 'Парковка' }},
        ],
        gallery: [
            "/imgs/gallery/lux/6.jpg",
            "/imgs/gallery/lux/2.jpg",
            "/imgs/gallery/lux/3.jpg",
            "/imgs/gallery/lux/4.jpg",
            "/imgs/gallery/lux/5.jpg",
            "/imgs/gallery/lux/1.jpg",
        ]
    },
    {
        internalId: 'studio-3-person',
        externalId: '13817',
        price: 1180,
        availability: 1,
        image: '/imgs/rooms/3-studio.png',
        title: {
            en: 'Studio Suite triple',
            uk: 'Студіо 3х місний',
            ru: 'Студио 3х местный'
        },
        description: {
            en: "The suite includes a living room, a well-equipped kitchen (a fridge, oven, kettle), dining zone and a bathroom with a shower. Air conditioning. High-speed Internet. Free parking.",
            uk: 'Апартаменти складаються з вітальні, добре обладнаної кухні з обідньою зоною та власної ванної кімнати з душем. Кондиціонер. Швидкісний інтернет. Надається парковка.',
            ru: 'Апартаменты состоят из гостиной, хорошо оборудованной кухни с обеденной зоной и собственной ванной комнаты с душем. Кондиционер. Скоростной интернет. Предоставляется парковка.'
        },
        capacity: {
            en: '3 placements',
            uk: '3 місця',
            ru: '3 места'
        },
        facilities: [
            { icon: '/icons/light/Dinner.png', title: { en: 'Dinner zone', uk: 'Обідня зона', ru: 'Обеденная зона' }},
            { icon: '/icons/light/Kitchens.png', title: { en: 'Own kitchen', uk: 'Власна кухня', ru: 'Собственная кухня' }},
            { icon: '/icons/light/Toilets.png', title: { en: 'Bathroom with all amenities', uk: 'Ванна з усіма зручностями', ru: 'Ванная со всеми удобствами' }},
            { icon: '/icons/light/Condicioner.png', title: { en: 'Air conditioner', uk: 'Кондиціонер', ru: 'Кондиционер' }},
            { icon: '/icons/light/TV.png', title: { en: 'TV', uk: 'ТБ', ru: 'Телевизор' }},
            { icon: '/icons/light/Dryer.png', title: { en: 'Hairdryer', uk: 'Фен', ru: 'Фен' }},
            { icon: '/icons/light/Parking.png', title: { en: 'Parking', uk: 'Парковка', ru: 'Парковка' }},
        ],
        gallery: [
            "/imgs/gallery/studio-3/4.jpg",
            "/imgs/gallery/studio-3/2.jpg",
            "/imgs/gallery/studio-3/3.jpg",
            "/imgs/gallery/studio-3/1.jpg",
            "/imgs/gallery/studio-3/5.jpg",
            "/imgs/gallery/studio-3/6.jpg",
            "/imgs/gallery/studio-3/7.jpg",
            "/imgs/gallery/studio-3/8.jpg",
            "/imgs/gallery/studio-3/9.jpg",
        ]
    },
    {
        internalId: 'apartments-3-person',
        externalId: '13819',
        price: 1300,
        availability: 1,
        image: '/imgs/rooms/3-persons.png',
        title: {
            en: 'Apartments triple',
            uk: 'Апартаменти 3х місні',
            ru: 'Апартаменты 3х местные'
        },
        description: {
            en: "The suite includes a living room, a well-equipped kitchen (a fridge, oven, kettle), dining zone and a bathroom with a shower. Air conditioning. High-speed Internet. Free parking.",
            uk: 'Апартаменти складаються з вітальні, добре обладнаної кухні з обідньою зоною та власної ванної кімнати з душем. Кондиціонер. Швидкісний інтернет. Надається парковка.',
            ru: 'Апартаменты состоят из гостиной, хорошо оборудованной кухни с обеденной зоной и собственной ванной комнаты с душем. Кондиционер. Скоростной интернет. Предоставляется парковка.'
        },
        capacity: {
            en: '3 placements',
            uk: '3 місця',
            ru: '3 места'
        },
        facilities: [
            { icon: '/icons/light/Dinner.png', title: { en: 'Dinner zone', uk: 'Обідня зона', ru: 'Обеденная зона' }},
            { icon: '/icons/light/Kitchens.png', title: { en: 'Own kitchen', uk: 'Власна кухня', ru: 'Собственная кухня' }},
            { icon: '/icons/light/Toilets.png', title: { en: 'Bathroom with all amenities', uk: 'Ванна з усіма зручностями', ru: 'Ванная со всеми удобствами' }},
            { icon: '/icons/light/Condicioner.png', title: { en: 'Air conditioner', uk: 'Кондиціонер', ru: 'Кондиционер' }},
            { icon: '/icons/light/TV.png', title: { en: 'TV', uk: 'ТБ', ru: 'Телевизор' }},
            { icon: '/icons/light/Dryer.png', title: { en: 'Hairdryer', uk: 'Фен', ru: 'Фен' }},
            { icon: '/icons/light/Parking.png', title: { en: 'Parking', uk: 'Парковка', ru: 'Парковка' }},
        ],
        gallery: [
            "/imgs/gallery/app-3/2.jpg",
            "/imgs/gallery/app-3/1.jpg",
            "/imgs/gallery/app-3/3.jpg",
            "/imgs/gallery/app-3/4.jpg",
            "/imgs/gallery/app-3/5.jpg",
            "/imgs/gallery/app-3/6.jpg",
        ]
    },
    {
        internalId: 'apartments-1-floor',
        externalId: '13814',
        price: 1350,
        availability: 1,
        image: '/imgs/rooms/app-1-floor.jpg',
        title: {
            en: 'Apartments 1 floor',
            uk: 'Апартаменти 1 этаж',
            ru: 'Аппартаменты 1 этаж'
        },
        description: {
            en: "The suite includes a living room, a well-equipped kitchen (a fridge, oven, kettle), dining zone, a bedroom with a big bed and a bathroom with a shower. Air conditioning. High-speed Internet. Free parking.",
            uk: 'Апартаменти складаються з вітальні, добре обладнаної кухні з обідньою зоною, окремої спальні з великим ліжком та власної ванної кімнати з душем. Кондиціонер. Швидкісний інтернет. Надається парковка.',
            ru: 'Апартаменты состоят из гостиной, хорошо оборудованной кухни с обеденной зоной, отдельной спальни с большой кроватью и собственной ванной комнаты с душем. Кондиционер. Скоростной интернет. Предоставляется парковка.'
        },
        capacity: {
            en: '4 placements',
            uk: '4 місця',
            ru: '4 места'
        },
        facilities: [
            { icon: '/icons/light/Dinner.png', title: { en: 'Dinner zone', uk: 'Обідня зона', ru: 'Обеденная зона' }},
            { icon: '/icons/light/Kitchens.png', title: { en: 'Own kitchen', uk: 'Власна кухня', ru: 'Собственная кухня' }},
            { icon: '/icons/light/Rooms.png', title: { en: 'Bedroom', uk: 'Спальня', ru: 'Спальня' }},
            { icon: '/icons/light/Toilets.png', title: { en: 'Bathroom with all amenities', uk: 'Ванна з усіма зручностями', ru: 'Ванная со всеми удобствами' }},
            { icon: '/icons/light/Condicioner.png', title: { en: 'Air conditioner', uk: 'Кондиціонер', ru: 'Кондиционер' }},
            { icon: '/icons/light/TV.png', title: { en: 'TV', uk: 'ТБ', ru: 'Телевизор' }},
            { icon: '/icons/light/Dryer.png', title: { en: 'Hairdryer', uk: 'Фен', ru: 'Фен' }},
            { icon: '/icons/light/Parking.png', title: { en: 'Parking', uk: 'Парковка', ru: 'Парковка' }},
        ],
        gallery: [
            "/imgs/gallery/app-1-floor/1.jpg",
            "/imgs/gallery/app-1-floor/2.jpg",
            "/imgs/gallery/app-1-floor/3.jpg",
            "/imgs/gallery/app-1-floor/4.jpg",
            "/imgs/gallery/app-1-floor/5.jpg",
            "/imgs/gallery/app-1-floor/6.jpg",
        ]
    },
];

export const defaultRoom = appData[2];