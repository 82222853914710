import {Button, DarkThemeToggle, Navbar} from "flowbite-react";
import {FaMapMarkedAlt } from "react-icons/fa";
import {FaBed, FaFacebook, FaInstagram} from "react-icons/fa6";
import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {AppContext, AppLanguage} from "../../App.context";
import {useTranslation} from "react-i18next";
import {Link, NavLink, useNavigate} from "react-router-dom";
import { useOnClickOutside } from "../../lib/click-outside.hook";
import logo_ru from '../../icons/logo_ru.svg';
import logo_uk from '../../icons/logo_uk.svg';
import {defaultRoom} from "../../app.data";

export default function HeaderComponent() {
    const {t} = useTranslation();
    const {language, switchLanguage} = useContext(AppContext);
    const navigate = useNavigate();
    const [isScroller, setScrolled] = useState(false);

    const handleScroll = useMemo(() => () => {
        if (window.scrollY > 0) setScrolled(true);
        if (window.scrollY <= 0) setScrolled(false);

    }, []);


    useEffect(() => {
        window.addEventListener('scroll', handleScroll, false);
    }, [handleScroll]);


    const [isOpenCollapse, _] = useState(false);



    const close = useMemo(() => (e: React.MouseEvent | any) => {
        e.preventDefault();

        if (!isOpenCollapse) {
            const toggleBtn = document.getElementById('testId') as HTMLButtonElement;
            if (toggleBtn) toggleBtn.click();

        }

    }, [isOpenCollapse]);

    const callTo = useMemo(() => (e: React.MouseEvent | any, link: string) => {
        e.preventDefault();

        close(e);

        window.location.href = link;
    }, [close]);

    const goToAddress = (e: any) => {
        close(e);

        const body = document.body,
            html = document.documentElement;

        const height = Math.max( body.scrollHeight, body.offsetHeight,
            html.clientHeight, html.scrollHeight, html.offsetHeight );

        window.scrollTo({
            top: height,
            behavior: 'smooth'
        });
    }

    const goToRooms = (e: any) => {
        close(e);

        navigate(`/rooms/${defaultRoom.internalId}`, {unstable_viewTransition: true});
    }

    const langBlockRef = useRef<HTMLDivElement>(null);
    const [isLangChange, changeLang] = useState(false);

    const handleClickOutside = () => {
        changeLang(false);
    }

    const switchLang = (lang: AppLanguage) => {
        switchLanguage(lang);
        changeLang(false);
    }

    useOnClickOutside(langBlockRef, handleClickOutside)

    return (
        <Navbar id={'main_header'} className={isScroller ? 'md:!h-[80px]' : ''}>
            <NavLink to={'/'} unstable_viewTransition={true}>
            <Navbar.Brand as={'div'} className={'flex flex-col content-start'}>
                    <>
                        <img src={language === 'ru' ? logo_ru : logo_uk}
                             className={`${isScroller ? 'md:!w-[80px]' : 'self-center '} w-[80px] lg:w-full md:w-[180px] sm:w-[100px]`}
                             alt="Mayak Odessa Hotel"/>
                        {!isScroller && (
                            <>
                                      <span
                                          className="hidden md:block lg:text-xl md:text-[18px] sm:text-[10px] text-[8px] self-center text-white whitespace-nowrap dark:text-white">{t('header.title')}</span>
                                <span
                                    className="hidden md:block lg:text-xl md:text-[18px] sm:text-[10px] text-[8px] self-center text-white whitespace-nowrap dark:text-white">{t('header.address')}</span>
                            </>
                        )}
                    </>
            </Navbar.Brand>
            </NavLink>
            <div className={'flex flex-row gap-2 md:hidden'}>
                <div ref={langBlockRef} className={'flex md:hidden flex-row gap-1 lg:gap-7 content-center justify-center '}>

                    <div className={'relative flex flex-col z-10'}>
                        <Button size={'xs'}
                                className={'flex rounded-full h-[40px] w-[40px] lg:h-[50px] lg:w-[50px] disabled:bg-light-bg-navbar disabled:dark:bg-light-bg disabled:dark:text-light-text z-10'}
                                color={'navbar-lang'}
                                onClick={_ => changeLang(!isLangChange)}
                        >
                            {language.toUpperCase()}
                        </Button>

                        <div
                            className={`${isLangChange ? 'opacity-100 top-full' : 'opacity-0 top-0'} absolute flex flex-col gap-1 bg-light-bg-navbar dark:bg-dark-bg rounded-full mt-1 transition-all duration-500`}>
                            <Button size={'xs'}
                                    className={'flex rounded-full h-[40px] w-[40px] lg:h-[50px] lg:w-[50px] disabled:bg-light-bg-navbar disabled:dark:bg-light-bg disabled:dark:text-light-text'}
                                    color={'navbar-lang'} disabled={language === 'uk'}
                                    onClick={_ => switchLang('uk')}
                            >
                                UK
                            </Button>

                            <Button size={'xs'}
                                    className={'flex rounded-full h-[40px] w-[40px] lg:h-[50px] lg:w-[50px] disabled:bg-light-bg-navbar disabled:dark:bg-light-bg disabled:dark:text-light-text'}
                                    color={'navbar-lang'} disabled={language === 'en'}
                                    onClick={_ => switchLang('en')}
                            >
                                EN
                            </Button>

                            <Button size={'xs'}
                                    className={'flex rounded-full h-[40px] w-[40px] lg:h-[50px] lg:w-[50px] disabled:bg-light-bg-navbar disabled:dark:bg-light-bg disabled:dark:text-light-text'}
                                    color={'navbar-lang'} disabled={language === 'ru'}
                                    onClick={_ => switchLang('ru')}
                            >
                                RU
                            </Button>
                        </div>
                    </div>

                    <DarkThemeToggle
                        className={'rounded-full items-center justify-center flex lg:h-[50px] lg:w-[50px] !ring-0'}/>


                </div>
                <Navbar.Toggle id={'testId'}/>
            </div>

            <Navbar.Collapse>
                <div
                    className={`mb-10 mt-10 landscape:mt-0 flex flex-col items-center basis-full gap-y-28 landscape:gap-y-6 md:mb-0 md:mt-0 md:gap-2 lg:flex-row md:flex-row lg:gap-10 xl:gap-24`}>

                    {/*<Link to={`/rooms/${firstRoom?.internalId}`} unstable_viewTransition={true} reloadDocument={true}>*/}
                        <Button size={'xs'} className={'flex flex-row px-0 py-0 p-0 lg:px-4 lg:py-2 gap-1'}
                                color={'navbar-bordered'}
                                onClick={goToRooms}
                        >
                            <FaBed/>

                            <span className={'pl-1'}> {t('header.our_rooms')}</span>
                        </Button>
                    {/*</Link>*/}

                    <div className={`flex ${isScroller ? 'flex-col' : 'flex-col md:flex-row'} md:gap-2 z-10`}>
                        <div className={`flex ${isScroller ? 'flex-col md:flex-row' : 'flex-col'}`}>
                            <Button color={'navbar'} size={'xs'} className={'flex text-[15px] rounded-xl'}
                                    onClick={e => callTo(e, 'tel:+380983278737')}>
                                <img src='/icons/kyivstar.png' width={20} height={20}
                                     className={isScroller ? 'mr-2 md:mr-0' : 'mr-2'}/>

                                <p className={isScroller ? 'md:hidden' : 'block'}>+38 098 327 87 37</p>

                            </Button>

                            <Button color={'navbar'} size={'xs'} className={'flex rounded-xl'}
                                    onClick={e => callTo(e, 'tel:+380504926000')}>
                                <img src='/icons/vodafone.png' width={20} height={20}
                                     className={isScroller ? 'mr-2 md:mr-0' : 'mr-2'}/>


                                <p className={isScroller ? 'md:hidden' : 'flex'}>+38 050 492 60 00</p>
                            </Button>
                        </div>

                        <div className={`flex ${isScroller ? 'flex-row' : 'flex-row md:flex-col'} justify-center pt-2 md:pt-0`}>
                            <Link to={'https://www.facebook.com/profile.php?id=61557368323337'} target={'_blank'}>
                                <Button color={'navbar'} size={'xs'} className={'flex rounded-xl '}>
                                    <FaFacebook  size={20}/>
                                </Button>
                            </Link>

                            <Link to={'https://www.instagram.com/lighthouse_odessa_hotel/'}  target={'_blank'}>
                                <Button color={'navbar'} size={'xs'} className={'flex rounded-xl'} >
                                    <FaInstagram size={20}/>
                                </Button>
                            </Link>
                        </div>


                    </div>


                    <Button color={'navbar'} size={'xs'} className={'flex rounded-xl'}
                            onClick={goToAddress}>
                        <FaMapMarkedAlt className={isScroller ? 'mr-2 md:mr-0' : 'mr-2'} size={20}/>
                        <p className={isScroller ? 'md:hidden' : 'flex'}>{t('header.how_to_get')}</p>
                    </Button>

                    <div ref={langBlockRef} className={'hidden md:flex flex-row gap-1 content-center justify-center z-10'}>

                        <div className={'relative flex flex-col '}>
                            <Button size={'xs'}
                                    className={' flex rounded-full h-[40px] w-[40px] lg:h-[50px] lg:w-[50px] disabled:bg-light-bg-navbar disabled:dark:bg-light-bg disabled:dark:text-light-text z-20 !ring-0'}
                                    color={'navbar-lang'}
                                    onClick={ _ => changeLang(!isLangChange)}
                            >
                                {language.toUpperCase()}
                            </Button>

                            <div className={`${isLangChange ? 'opacity-100 top-full' : 'opacity-0 top-0' } absolute flex flex-col gap-1 bg-light-bg-navbar dark:bg-dark-bg rounded-full mt-1 transition-all duration-500`}>
                                <Button size={'xs'}
                                        className={'flex rounded-full h-[40px] w-[40px] lg:h-[50px] lg:w-[50px] disabled:bg-light-bg-navbar disabled:dark:bg-light-bg disabled:dark:text-light-text'}
                                        color={'navbar-lang'} disabled={language === 'uk'}
                                        onClick={_ => switchLang('uk')}
                                >
                                    UK
                                </Button>

                                <Button size={'xs'}
                                        className={'flex rounded-full h-[40px] w-[40px] lg:h-[50px] lg:w-[50px] disabled:bg-light-bg-navbar disabled:dark:bg-light-bg disabled:dark:text-light-text'}
                                        color={'navbar-lang'} disabled={language === 'en'}
                                        onClick={_ => switchLang('en')}
                                >
                                    EN
                                </Button>

                                <Button size={'xs'}
                                        className={'flex rounded-full h-[40px] w-[40px] lg:h-[50px] lg:w-[50px] disabled:bg-light-bg-navbar disabled:dark:bg-light-bg disabled:dark:text-light-text'}
                                        color={'navbar-lang'} disabled={language === 'ru'}
                                        onClick={_ => switchLang('ru')}
                                >
                                    RU
                                </Button>
                            </div>
                        </div>

                        <DarkThemeToggle
                            className={'rounded-full items-center justify-center flex lg:h-[50px] lg:w-[50px] !ring-0'}/>

                    </div>
                </div>

            </Navbar.Collapse>
        </Navbar>
    );
}