import {Footer} from "flowbite-react";
import {useTranslation} from "react-i18next";
import {useContext} from "react";
import {AppContext} from "../../App.context";
import logo_ru from '../../icons/logo_ru.svg';
import logo_uk from '../../icons/logo_uk.svg';

export default function FooterComponent() {
    const {t} = useTranslation();
    const {language} = useContext(AppContext);

    return (
        <div className={'w-full flex flex-col items-center bg-light-main/80 dark:bg-dark-bg/70'}>
            <div className={'flex container shadow-xl shadow-black dark:shadow-gray-400'}>
                <Footer className={'relative bg-transparent h-full flex flex-col'}>
                    <img src='/footer-2.png' className={''} alt={'footer'}/>

                    <div
                        className={`static lg:absolute flex flex-col lg:top-0 w-full lg:left-2/4 lg:w-[350px] xl:w-[500px] h-full bg-light-main/70 dark:bg-dark-bg/70 pb-2`}>
                        <img src={language === 'ru' ? logo_ru : logo_uk}
                             alt={'Logo'}
                             className={'block self-center pt-2 w-[80px] md:w-[120px] lg:w-[150px] xl:w-[200px] lg:pt-10 xl:pt-16'}/>

                        <div className={'flex flex-col self-center sm:py-2 md:py-4 lg:py-6 xl:py-8'}
                             id={'address'}>

                                          <span
                                              className="block text-white text-xs text-center md:text-sm lg:text-lg">{t('header.title')}</span>
                            <span
                                className="block text-white text-xs text-center md:text-sm lg:text-lg">{t('header.address')}</span>
                        </div>

                        <iframe
                            title={'Google Map'}
                            src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d477.12467594516846!2d30.733032432133605!3d46.405882030626344!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c6334ea39ee003%3A0x5b68b9392049eac4!2sOtel%CA%B9%20Mayak%20Odessa!5e0!3m2!1s${language}!2sua!4v1710383009773!5m2!1s${language}!2sua&hl=${language}`}
                            className={'border-0 w-full h-[350px] sm:h-[500px] lg:h-full p-2 md:p-4 md:pb-2 xl:p-6 xl:pb-4 pb-0'}
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade" />
                    </div>
                </Footer>
            </div>
        </div>
    );
}