import React from 'react';
import './App.css';
import {I18nextProvider} from "react-i18next";
import i18n from "./i18n";
import {AppProvider} from "./App.context";
import {RouterProvider} from "react-router-dom";
import {router} from "./App.router";


export const App = () => {
    return (
        <I18nextProvider i18n={i18n}>
            <AppProvider>
                <RouterProvider router={router} />
            </AppProvider>
        </I18nextProvider>
    );
}

export const testDimension = "!bg-purple-100 sm:!bg-green-200 md:!bg-blue-100 lg:!bg-yellow-100 xl:!bg-brown-100";
