import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {Flowbite} from "flowbite-react";
import {flowbiteTheme} from "./flowbite.theme";
import {App} from "./App";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

window.addEventListener('DOMContentLoaded', (e) => {
    console.log('loaded content window', e)
    document.getElementById('page_loader')?.remove();
});


root.render(
  <React.StrictMode>
      <Flowbite theme={{theme: flowbiteTheme}}>
          <App />
      </Flowbite>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
