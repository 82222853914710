export const day = 1000 * 60 * 60 * 24;

export const minArrivalDate = new Date(new Date().setHours(0,0,0,0));
export const minDepartureDate = new Date(new Date().setHours(0,0,0,0) + day);

export const convertArrivalDate = (date: Date): Date => {
    return new Date(date.setHours(12, 0, 0, 0));
}

export const convertDepartureDate = (date: Date): Date => {
    return new Date(date.setHours(12, 0, 0, 0));
}

export const diffInDays = (date1: Date, date2: Date): number => {
    const diffInMs = Math.abs(date2.getTime() - date1.getTime());
    return Math.floor(diffInMs / day);
}