import HeaderComponent from "../components/Header/Header";
import {Outlet} from "react-router-dom";
import FooterComponent from "../components/Footer/Footer";
import React, {useContext, useRef, useState} from "react";
import {Button, Carousel} from "flowbite-react";
import {AppContext} from "../App.context";
import {useOnClickOutside} from "../lib/click-outside.hook";
import { IoIosCloseCircleOutline } from "react-icons/io";

export default function LayoutPage() {
    const {fullScreenImages, showFullScreenImages} = useContext(AppContext);

    const [isImgLoaded, setImgLoaded] = useState(0);

    const carouselBlockRef = useRef<HTMLDivElement>(null);

    const handleClose = () => {
        showFullScreenImages([]);
        setImgLoaded(0)
    }

    useOnClickOutside(carouselBlockRef, handleClose)

    return (
        <>

            <div className={`transition-all duration-500 flex flex-row basis-full w-full h-full`}
            >

                <div className={' !max-w-full flex flex-col basis-full flex-shrink-0 w-full relative'}>
                    <div className={'w-full self-center flex flex-col basis-full'} id='container'>
                        <HeaderComponent/>


                        <div

                            className={'transition-all duration-500 flex flex-col container !max-w-full w-full absolute top-0 bg-light-container-bg dark:bg-dark-container-bg'}
                        >

                            <Outlet/>

                            <FooterComponent/>
                        </div>
                    </div>
                </div>
            </div>

            {fullScreenImages.length > 0 && (
                <>
                    <div
                        className={`${isImgLoaded === fullScreenImages.length ? `` : 'animate-pulse'} fixed z-[99] w-full h-full flex flex-col basis-full bg-black/90 justify-center items-center`}>
                        <div className="flex flex-col basis-full w-10/12">
                            <div className={'flex w-full h-full'}>
                                <Carousel

                                    // onSlideChange={e => changeImage(undefined, e)}
                                    draggable={true}
                                    slide={false}
                                    className={`rounded-xl py-2`}
                                >

                                    {fullScreenImages.map((src, index) => (
                                        <img

                                            onLoad={_ => setImgLoaded(prevState => prevState + 1)}
                                            src={src} key={index} alt={src} className={`rounded-xl `}/>
                                    ))}

                                </Carousel>
                            </div>
                        </div>
                    </div>

                    <Button
                        onClick={_ => handleClose()}
                        size={'sm'}
                        color={'default'}
                        className={'fixed !z-[100] right-1 top-1 w-8 md:right-5 md:top-5 md:w-12 md:h-12'}
                    >
                        {/*<IoCloseCircleOutline className={'h-full'} />*/}
                        <IoIosCloseCircleOutline/>
                    </Button>
                </>
            )}

        </>
    );
}