import {createHashRouter} from "react-router-dom";
import LayoutPage from "./pages/layout.page";
import MainPage from "./pages/main.page";
import ErrorPage from "./pages/error.page";
import RoomPage from "./pages/room.page";
import BookingPage from "./pages/booking.page";
import BookingAcceptPage from "./pages/booking-accept.page";


export const router = createHashRouter([
    {
        path: '/',
        element: <LayoutPage/>,
        errorElement: <ErrorPage/>,
        children: [
            {
                index: true,
                element: <MainPage />
            },
            {
                path: '/rooms/:id',
                element: <RoomPage />
            },
            {
                path: '/book',
                element: <BookingPage />
            },
            {
                path: '/book/accept',
                element: <BookingAcceptPage />
            }
        ]
    },
]);