import {TFunction} from "i18next";
import {isValidPhoneNumber} from "react-phone-number-input";

export class Validations {
    static phoneValidation = (value: string, t: TFunction) => {
        return (value.length === 0 || isValidPhoneNumber(value)) || t('validations.phone');
    }

    static email = (value: string, t: TFunction) => {
        const regex = /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/igm;
        return (value.length === 0 || regex.test(value)) || t('validations.email');
    }

    static minTrimLength = (field: string, value: string, length: number, t: TFunction) => {
        let valid = true;
        if (value && value.startsWith(' ')) return t('validations.start_with_space');
        if (value && value.length > 0) valid = value.trim().length >= length;

        return valid || t('validations.min_length', {field: field, length: length});
    }

    static maxTrimLength = (field: string, value: string, length: number, t: TFunction) => {
        let valid = true;
        if (value && value.startsWith(' ')) return t('validations.start_with_space');
        if (value && value.length > 0) valid = value.trim().length <= length;

        return valid || t('validations.max_length', {field: field, length: length});
    }

    static trimLength = (field: string, value: string, length: number, t: TFunction) => {
        let valid = true;
        if (value && value.startsWith(' ')) return t('validations.start_with_space');
        if (value && value.length > 0) valid = value.trim().length === length;

        return valid || t('validations.length', {field: field, length: length});
    }

    static text = (field: string, value: string, t: TFunction, allowMinus = true, allowSpaces = true) => {
        const allReg = /^[\p{L}0-9\s-]+$/u;
        const withMinusReg = /^[\p{L}0-9-]+$/u;
        const withSpacesReg = /^[\p{L}0-9\s]+$/u;

        if (allowMinus && !allowSpaces) return withMinusReg.test(value) || t('validations.text.only_dash', {field: field});
        if (!allowMinus && allowSpaces) return withSpacesReg.test(value) || t('validations.text.only_spaces', {field: field});

        return (value.length === 0 || allReg.test(value)) || t('validations.text.all', {field: field});
    }
}