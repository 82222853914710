import {Button, Card, Datepicker} from "flowbite-react";
import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {AppContext} from "../../App.context";
import {convertArrivalDate, convertDepartureDate, day} from "../../lib/utils";
import {useNavigate} from "react-router-dom";

export default function SearchRoomCardComponent({top = true}: {top?: boolean}) {
    const {t} = useTranslation();
    const {language, changeArrivalDate, changeDepartureDate} = useContext(AppContext);
    const navigate = useNavigate();

    const minFrom = new Date();
    const [arrival, setArrival] = useState(convertArrivalDate(minFrom));
    const [minTo, setMinTo] = useState(new Date(arrival.getTime()  + day));
    const [departure, setDeparture] = useState(convertDepartureDate(minTo));

    const handleArrivalDate = (date: Date) => {
        const minToDate = new Date(date.getTime() + day);

        setArrival(convertArrivalDate(date));
        setMinTo(minToDate)

        if (departure < minToDate) setDeparture(minToDate);
    }

    const handleDepartureDate = (date: Date) => {
        setDeparture(convertDepartureDate(date));
    }

    const search = () => {
        changeArrivalDate(arrival);
        changeDepartureDate(departure);

        navigate('/book', {unstable_viewTransition: true})
    }

    return (
        <Card className={`w-full ${top ? 'rounded-b-xl rounded-t-none' : 'rounded-t-xl rounded-b-none'}`}>
            <div className={'flex flex-col basis-full gap-6 py-6 lg:px-20 xl:px-44 lg:flex-row'}>
                <div className={'flex flex-col basis-1/3 px-6 lg:px-0'}>
                    <div className={'py-2'}>
                        {t('input.date_arrival')}
                    </div>
                    <Datepicker
                        showTodayButton={false}
                        showClearButton={false}
                        sizing={'lg'}
                        className={''}
                        language={language}
                        title={t('input.date_arrival')}

                        onSelectedDateChanged={handleArrivalDate}
                        minDate={minFrom}
                        defaultDate={arrival}
                        value={arrival.toLocaleDateString()}
                    />
                </div>
                <div className={'flex flex-col basis-1/3 px-6 lg:px-0'}>
                    <div className={'py-2'}>
                        {t('input.date_departure')}
                    </div>
                    <Datepicker
                        sizing={'lg'}
                        title={t('input.date_departure')}
                        showTodayButton={false}
                        showClearButton={false}
                        language={language}

                        onSelectedDateChanged={handleDepartureDate}
                        minDate={minTo}
                        defaultDate={departure}
                        value={departure.toLocaleDateString()}
                    />
                </div>
                <div className={'flex flex-col justify-end basis-1/3 px-6 lg:px-0'}>
                    <Button size={'xl'} color={'default'} className={'w-full'} onClick={search}>{t('button.find_rooms')}</Button>
                </div>
            </div>
        </Card>
    )
}