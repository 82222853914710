import React from "react";
import {useTranslation} from "react-i18next";

export default function HotelAdvantages() {
    const {t} = useTranslation();

    return (
        <div className={'flex flex-col w-full items-center sm:gap-20 pb-16 dark:text-white'}>

            <div
                className={'flex flex-col w-full gap-0 sm:flex-row md:w-7/8 lg:w-9/12 xl:1/2 lg:gap-16'}>

                <div className={'flex flex-col basis-full items-center'}>
                    <img src={'/icons/Clock.png'}
                         className={'dark:brightness-0 dark:contrast-200'} alt={t('advantage.settlement')}/>

                    <p className={'text-center'}>{t('advantage.settlement')}</p>
                </div>

                <div className={'flex flex-col basis-full items-center'}>
                    <img src='/icons/Transfer.png'
                         className={'dark:brightness-0 dark:contrast-200'} alt={t('advantage.transfer')}/>

                    <p className={'text-center'}>{t('advantage.transfer')}</p>
                </div>

                <div className={'flex flex-col basis-full items-center'}>
                    <img src='/icons/Reception.png'
                         className={'dark:brightness-0 dark:contrast-200'} alt={t('advantage.reception')}/>

                    <p className={'text-center'}>{t('advantage.reception')}</p>
                </div>

            </div>

            <div
                className={'flex flex-col w-full gap-0 sm:flex-row md:w-7/8 lg:w-9/12 xl:1/2 lg:gap-16'}>

                <div className={'flex flex-col basis-full items-center'}>
                    <img src='/icons/Coffe.png' className={'dark:brightness-0 dark:contrast-200'} alt={t('advantage.coffee')}/>

                    <p className={'text-center'}>{t('advantage.coffee')}</p>
                </div>

                <div className={'flex flex-col basis-full items-center'}>
                    <img src='/icons/Print.png' className={'dark:brightness-0 dark:contrast-200'} alt={t('advantage.print')}/>

                    <p className={'text-center'}>{t('advantage.print')}</p>
                </div>

                <div className={'flex flex-col basis-full items-center'}>
                    <img src='/icons/Taxi.png' className={'dark:brightness-0 dark:contrast-200'} alt={t('advantage.taxi')}/>

                    <p className={'text-center'}>{t('advantage.taxi')}</p>
                </div>

            </div>

            <div
                className={'flex flex-col w-full gap-0 sm:flex-row md:w-7/8 lg:w-9/12 xl:1/2 lg:gap-16'}>

                <div className={'flex flex-col basis-full items-center'}>
                    <img src='/icons/Rooms.png' className={'dark:brightness-0 dark:contrast-200'} alt={t('advantage.rooms')}/>

                    <p className={'text-center'}>{t('advantage.rooms')}</p>
                </div>

                <div className={'flex flex-col basis-full items-center'}>
                    <img src='/icons/Kitchens.png'
                         className={'dark:brightness-0 dark:contrast-200'}
                    alt={t('advantage.kitchen')}/>

                    <p className={'text-center'}>
                        {t('advantage.kitchen')}
                    </p>
                </div>

                <div className={'flex flex-col basis-full items-center'}>
                    <img src='/icons/Toilets.png'
                         alt={t('advantage.wc')}
                         className={'dark:brightness-0 dark:contrast-200'}/>

                    <p className={'text-center'}>
                        {t('advantage.wc')}
                    </p>
                </div>

            </div>

        </div>
    );
}